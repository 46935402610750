<template>
  <div class="container-sm">
    <div class="text-center">
      <div class="my-5">
        <h1>Sign in</h1>
        <p>{{ email }}</p>
      </div>
    </div>
    <form novalidate @submit.prevent="authenticate">
      <div class="d-flex j-space-between mb-1">
        <label for="#">Password</label>
        <a @click="this.$router.push('/reset-password');" href="#">Forgot password?</a>
      </div>
      <div class="mb-1">
        <input v-model="password" class="rv-input mb-1" type="password" placeholder="Your password">
        <div :class="{ 'show ': pswd_err, 'form-err': true }">{{ pswd_err }}</div>
      </div>
      <label class="mt-1"><input v-model="keepSignedIn" type="checkbox"><span>Keep me signed in</span></label>
      <button class="rv-button mt-4 w-100">Sign in</button>
      <div :class="{ 'show': form_err, 'form-err mt-2 text-center': true }">{{ form_err }}</div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SignInForm',
  props: ['email'],
  data() {
    return {
      pswd_err: null,
      form_err: null,
      password: null,
      keepSignedIn: null,
    }
  },
  mounted() {
    // Check if the email prop was set
    if (!this.email) {
      // If not set, you can redirect or display an error message
      // For example, redirect to the home route ("/")
      this.$router.push('/welcome');
    }
  },
  methods: {
  async authenticate() {
    this.pswd_err = null;
    this.form_err = null;


    try {
      const response = await axios.post('https://api.revio.pro/v1/accounts/signin', {
          email: this.email,
          password: this.password,
          keepSignedIn: this.keepSignedIn
      }, {
          withCredentials: true // Include credentials with the request
      });

      if (response.status === 200 && response.data.success && response.data.verified ) {
        // Redirect to app.revio.pro upon successful login
        window.location.href = 'https://app.revio.pro';
      } else if( response.status === 200 && !response.data.verified ) {
        this.$router.push('/activate-account');
      } else {  
        // Handle unsuccessful login
        this.form_err = 'There was an error. Please try again.';
      }
      
      

    } catch (error) {
      this.form_err = error.response.data.error;
    }
  }
}
};
</script>

<style scoped></style>
