<template>
<div class="container-sm">
  <div class="text-center">
    <img width="70" src="https://assets.revio.pro/images/rv-brand/revio-symbol.svg" alt="logo">
    <div class="my-5">
      <h1>Welcome</h1>
      <p>Enter your email to get started.</p>
    </div>
  </div>
  <form novalidate @submit.prevent="confirm">
    <div class="d-flex j-space-between mb-1">
      <label for="#">Email</label>
    </div>
    <div class="mb-1">
      <input name="email" v-model="email" class="rv-input mb-1" type="email" placeholder="Your email">
      <div :class="{ 'show ': email_err, 'form-err': true }">{{ email_err }}</div>
    </div>
    <button class="rv-button mt-4 w-100">Continue</button>
    <div :class="{ 'show': form_err, 'form-err mt-2 text-center': true }">{{ form_err }}</div>
  </form>
</div>
</template>

<script>
import axios from 'axios';

export default {
name: 'WelcomeForm',
data() {
  return {
    inputValue: '',
    email_err: null,
    form_err: null,
    errorMessage: false,
    email: ''
  };
},
methods: {
  async confirm() {
    this.email_err = null;
    this.form_err = null;

    if (!this.email || this.email.trim() === '') {
      this.email_err = 'Email is required';
    } else if (!this.isValidEmail(this.email)){
      this.email_err = 'Enter a valid email';
    } else {
      try {
        const response = await axios.post('https://api.revio.pro/v1/accounts/check-email', {
          email: this.email
        });

        this.$emit('welcome-email', this.email);

      

        if ( response.data ){
          console.log(response.data);
          if ( response.data.google_account === 1 ){
            window.location.href = "https://api.revio.pro/v1/accounts/signin/google/"+response.data.email;
          } else {
            this.$router.push('/signin');
          }
        } else {
          this.$router.push('/signup');
        }
        
      } catch (error) {
        this.form_err = error.response.data.error;
      }
    }
  },
  isValidEmail(email) {
    // Regular expression for validating email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
}
};
</script>

<style scoped></style>
