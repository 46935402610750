<template>
  <div class="container-sm">
    <div class="text-center">
      <div v-show="success === true" class="my-5">
        <h1>Success</h1>
        <p>Your account has been successfully activated.</p>
      </div>
      <div v-show="err" class="my-5">
        <h1>Ooops!</h1>
        <p>This link is not valid.</p>
      </div>
    </div>
      <a @click="this.$router.push('/signin');" href="#">
        <button class="rv-button mt-4 w-100">Continue</button>
      </a>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AccountActivated',
  data() {
    return {
      email: null,
      token: null,
      success: null,
      err: null
    }
  },
  mounted() {
    // Access route parameters
    const email = this.$route.params.email.trim();
    const token = this.$route.params.token.trim();

    // Check if both email and token exist
    if (email && token && this.isValidEmail(email) ) {

      this.email = email;
      this.token = token;

      this.activateAccount();
      
    } else {
      this.$router.push('/welcome');
    }
    
  },
  methods: {
    isValidEmail(email) {
      // Regular expression for validating email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // Test if the email matches the regex pattern
      return emailRegex.test(email);
    },
    async activateAccount() {
      


      try {
        const response = await axios.post('https://api.revio.pro/v1/accounts/activate', {
            email: this.email,
            token: this.token,
        });

        if (response.status === 200 && response.data.success ) {
          // Redirect to app.revio.pro upon successful login
          this.success = true;
        } else {  
          this.err = response.data.error;
        }
        
          

      } catch (error) {
        this.err = error.response.data.error;
      }
    }
  },
};
</script>

<style scoped></style>
