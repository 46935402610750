<template>
  <div class="container-sm">
    <div class="text-center">
      <h1>Sign up to get Started</h1>
      <div class="my-3">
        <a :href="'https://api.revio.pro/v1/accounts/signin/google/'+mail">
          <button class="gsi-material-button">
            <div class="gsi-material-button-state"></div>
            <div class="gsi-material-button-content-wrapper">
              <div class="gsi-material-button-icon">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"
                  xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                  <path fill="#EA4335"
                    d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z">
                  </path>
                  <path fill="#4285F4"
                    d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z">
                  </path>
                  <path fill="#FBBC05"
                    d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z">
                  </path>
                  <path fill="#34A853"
                    d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z">
                  </path>
                  <path fill="none" d="M0 0h48v48H0z"></path>
                </svg>
              </div>
              <span class="gsi-material-button-contents">Sign up with Google</span>
              <span style="display: none;">Sign up with Google</span>
            </div>
          </button>
        </a>
      </div>
      <div class="or-spacer mb-3"></div>
    </div>
    <form @submit.prevent="signUp" autocomplete="off">
      <input autocomplete="false" name="hidden" type="text" style="display:none;">
      <div class="d-flex j-space-between mb-1">
        <label for="#">Email</label>
      </div>
      <div class="mb-1">
        <input v-model="mail" class="rv-input mb-1" type="email" placeholder="Your email">
        <div class="form-err" hidden>Email is required</div>
      </div>
      <div class="d-flex j-space-between mb-1">
        <label for="#">Full Name</label>
      </div>
      <div class="mb-1">
        <input v-model="fullName" name="fullname" class="rv-input mb-1" type="text" placeholder="Your Full Name" value="" autocomplete="one-time-code">
        <div class="form-err" hidden>Full Name is required</div>
      </div>
      <div class="d-flex j-space-between mb-1">
        <label for="#">Password</label>
      </div>
      <div class="mb-1">
        <input v-model="password" name="password" class="rv-input mb-1" type="password" placeholder="Your password" value="" autocomplete="one-time-code">
        <div class="form-err" hidden>Password is required</div>
      </div>
      <button class="rv-button mt-4 w-100">Sign up</button>
      <div :class="{ 'show': form_err, 'form-err mt-2 text-center': true }">{{ form_err }}</div>
    </form>
    <div style="font-size: 12px;" class="text-center text-secondary my-2">By signing up, you agree to our <u>Terms of
        Service</u>
      and <u>Privacy Policy.</u></div>
    <div style="font-size: 14px;" class="text-center text-secondary my-2">Already have an account? <a
        @click="this.$router.push('/welcome');" href="#">Login
        in</a>.</div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SignUpForm',
  props: ['email'],
  data() {
    return {
      mail: null,
      password: null,
      fullName: null,
      form_err: null,
    }
  },
  mounted() {
    // Check if the email prop was set
    if (!this.email) {
      // If not set, you can redirect or display an error message
      // For example, redirect to the home route ("/")
      this.$router.push('/welcome');
    } else {
      this.mail = this.email;
    }

  },
  methods: {
    async signUp() {

      try {
        const response = await axios.post('https://api.revio.pro/v1/accounts/signup', {
            email: this.mail,
            fullName: this.fullName,
            password: this.password,
            googleAccount: 0
        });

        if ( response && response.data.success ){
          this.$router.push('/activate-account');
        }
        

      } catch (error) {
        console.log(error);
        this.form_err = error.response.data.error;
      }
    }
  }
};
</script>

<style scoped></style>
