import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router'; // Import createRouter and createWebHistory
import WelcomeForm from './components/WelcomeForm.vue';
import SignUpForm from './components/SignUpForm.vue';
import SignInForm from './components/SignInForm.vue';
import ResetPassword from './components/ResetPassword.vue';
import NotFound from './components/NotFound.vue';
import ActivateAccount from './components/ActivateAccount.vue';
import AccountActivated from './components/AccountActivated.vue';

const routes = [
    {
        path: '/',
        redirect: '/welcome' // Redirect base route to /welcome
    },
    {
        path: '/welcome',
        component: WelcomeForm,
        name: 'Welcome',
        meta: { transition: 'fade' },
        props: true,
    },
    {
        path: '/activation/:email/:token',
        component: AccountActivated,
        name: 'Activate Account',
        meta: { transition: 'fade' },
        props: true,
    },
    {
        path: '/signup',
        component: SignUpForm,
        name: 'Sign Up',
        meta: { transition: 'fade-left' },
        props: true,
    },
    {
        path: '/signin',
        component: SignInForm,
        name: 'Sign In',
        meta: { transition: 'fade-left' },
        props: true,
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        name: 'Reset password',
        meta: { transition: 'fade' },
        props: true,
    },
    {
        path: '/activate-account',
        component: ActivateAccount,
        name: 'Activate account',
        meta: { transition: 'fade' },
        props: true,
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound,
        name: 'Not found',
        meta: { transition: 'fade' }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

// Add navigation guard to update page title
router.beforeEach((to, from, next) => {
    document.title = `${to.name}`; // Customize the website name as needed
    next();
});

const app = createApp(App);

// Use the router
app.use(router);

// Mount the app with the router
app.mount('#app');
