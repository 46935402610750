<template>
  <div class="container-sm">
    <div class="text-center">
      <div class="my-5">
        <h1>Check your inbox</h1>
        <p>We've sent an email to <strong>{{ email }}</strong> with instruction to activate your account.</p>
      </div>
    </div>
      <a @click="this.$router.push('/welcome');" href="#">
        <button class="rv-button mt-4 w-100">Sign in</button>
      </a>
  </div>
</template>

<script>
export default {
  name: 'ActivateAccount',
  props: ['email'],
  data() {
    return {}
  },
  mounted() {
    // Check if the email prop was set
    if (!this.email) {
      // If not set, you can redirect or display an error message
      // For example, redirect to the home route ("/")
      this.$router.push('/welcome');
    }
  }
};
</script>

<style scoped></style>
