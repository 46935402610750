<template>
  <div class="panel-container">
    <div class="left-panel"></div>
    <div class="right-panel">
      <router-view v-slot="{ Component, route }">
        <!-- Use a custom transition or fallback to `fade` -->
        <Transition :name="route.meta.transition || 'fade'" mode="out-in">
            <component :email="email"  @welcome-email="setEmail" :is="Component" />
        </Transition>
      </router-view>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      email: '', // Initialize email data property
      errorMessage: false
    };
  },
  mounted () {
    const urlParams = new URLSearchParams(window.location.search);
    const error = urlParams.get('error');

    // Display error message if 'error' parameter is present
    if (error) {
        this.errorMessage = error;
        alert(error);
    }
  },
  methods: {
   setEmail(email) {
      this.email = email;
    }
  }
}
</script>

<style scoped>
.panel-container {
  display: grid;
  grid-template-columns: 40% auto;
  grid-template-rows: auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.left-panel {
  background: rgb(29,14,38);
background: -moz-linear-gradient(135deg, rgba(29,14,38,1) 0%, rgba(36,18,47,1) 100%);
background: -webkit-linear-gradient(135deg, rgba(29,14,38,1) 0%, rgba(36,18,47,1) 100%);
background: linear-gradient(135deg, rgba(29,14,38,1) 0%, rgba(36,18,47,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1d0e26",endColorstr="#24122f",GradientType=1);
}

.left-panel::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("https://assets.revio.pro/images/rv-brand/revio-symbol.svg");
  background-size: 150%;
  background-position: 30%;
  background-color: var(--primary-color-dark);
  background-repeat: no-repeat;
  opacity: 0;
  animation: fadeInRight 1.2s ease forwards;
  /* Smoother animation with longer duration */
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    background-position: 75%;
  }

  to {
    opacity: 0.3;
    background-position: 50%;
  }
}

.right-panel {
  display: flex;
}

@media screen and (max-width: 900px) {
  .panel-container {
    display: block;
    height: 100%;
  }

  .left-panel {
    display: none;
  }

  .right-panel {
    flex-flow: row;
    -webkit-box-pack: start;
    place-content: stretch flex-start;
  }
}

/*Vue JS CSS Components*/
.fade-enter-active {
  transition: opacity 0.5s, transform 0.5s;
}

.fade-enter {
  transition: opacity 0.5s, transform 0.5s;
}

.fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}


.fade-left-enter-active {
  transition: opacity 0.5s, transform 0.5s;
}

.fade-left-enter {
  transition: opacity 0.5s, transform 0.5s;
}

.fade-left-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}


.fade-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

.fade-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.fade-left-leave-to {
  opacity: 0;
  transform: translateX(-50px);
}

.fade-left-enter-from {
  opacity: 0;
  transform: translateX(50px);
}
</style>
