<template>
    <div class="container-sm">
        <div class="text-center">
            <div class="my-5">
                <h1>Oops!</h1>
                <p>You've reached a digital dead end.</p>
                <a href="/">
                 <button class="rv-button mt-5 w-100">Return to safety</button>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound'
};
</script>

<style scoped></style>