<template>
  <div class="container-sm">
    <div class="text-center">
      <div class="my-5">
        <h1>Reset password</h1>
        <p>Please enter the email associated with your account.</p>
      </div>
    </div>
    <div class="d-flex j-space-between mb-1">
      <label for="email">Email</label>
      <a @click="this.$router.push('/signin');" href="#">Remember password?</a>
    </div>
    <div class="mb-1">
      <input id="email" class="rv-input mb-1" type="email" placeholder="your@email.com">
      <div class="form-err"></div>
    </div>
    <button class="rv-button mt-4 w-100">Reset password</button>

  </div>
</template>

<script>
export default {
  name: 'ResetPassword'
};
</script>

<style scoped></style>
